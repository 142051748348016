import MESSAGE_SQUARE from '../../static/images/message-square.svg';
import HELP_CIRCLE from '../../static/images/help-circle.svg';

export const FooterCardItems = [
  {
    title: 'Community and feedback',
    image: MESSAGE_SQUARE,
    link: 'https://forms.gle/pCFSM2vV4yzP1cuVA',
  },
  {
    title: 'Support',
    image: HELP_CIRCLE,
    link: '',
    email: 'support@skyflow.com',
  },
];
