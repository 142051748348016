import ROUTES from '../docs-config/src';
import VAULT from '../../static/images/fill-vault.svg';
import USERS from '../../static/images/fill-users.svg';
import NAV_CONNECTIONS from '../../static/images/fill-nav-connections.svg';

export const UseCaseCardItems = [
  {
    title: 'Securely store sensitive data in Vault',
    description: 'Learn the basics of Skyflow’s Data API to read and write sensitive data to a Vault.',
    link: ROUTES.productGetStarted,
    icon: VAULT
  },
  {
    title: 'Collect sensitive data from the client-side',
    description: 'Use Skyflow’s client-side SDKs to securely collect sensitive data from the client.',
    link: 'https://github.com/skyflowapi/skyflow-js#skyflow-js',
    icon: VAULT
  },
  {
    title: 'Govern access to sensitive data',
    description: 'Use Skyflow’s data governance capabilities to control access to your sensitive data.',
    link: ROUTES.dataGovernanceQuickstart,
    icon: USERS
  },
  {
    title: 'Use sensitive data with other APIs',
    description: 'Use Skyflow Connections to securely send sensitive data to downstream APIs like Stripe, Adyen, etc.',
    link: ROUTES.connectionsQuickstart,
    icon: NAV_CONNECTIONS
  },
];
