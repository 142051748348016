import * as React from 'react';
import { Grid } from '@mui/material';
import LinkWrapper from '../Link';
import { Box } from '@mui/system';
import Typography from '../Typography';
import { COLORS } from '../../../utils/theme';

const useStyles = {
    cardMediaStyles: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'flex-start',
        gap: '4px',
        padding: '22px 22px 20px 20px',
    },
    DemoscardMediaStyles: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '20px',
        padding: '30px 30px 30px 30px',
    },
    cardStyle: {
        border: '1px solid rgba(0, 0, 0, 0.18)',
        borderRadius: '4px',
        backgroundColor: COLORS.PRIMARY_WHITE,
        width: '100%',
        height: '100%',
        cursor: 'pointer'
    },
    useCaseCardStyle: {
        border: '1px solid rgba(0, 0, 0, 0.18)',
        borderRadius: '4px',
        backgroundColor: COLORS.PRIMARY_WHITE,
        width: '100%',
        height: '100%',
        cursor: 'pointer'
    },
    contentStyle: {
        display: 'flex',
        direction: 'row',
        alignItems: 'center',
        alignContent: 'center'
    },
    iconStyle: {
        display: 'flex',
        alignItems: 'center',
        maxWidth: '72px',
        paddingLeft: '5%',
        paddingRight: '1%'
    },
};
export interface HomePageCardProps {
    title: string;
    link: string;
    description?: string;
    image?: string;
    type?: string;
    email?: string;
    icon?: string;
}
const HomePageCard: React.FC<HomePageCardProps> = ({
    title,
    description,
    image,
    link,
    type,
    email,
    icon,
}) => {
    const handleEmailButtonClick = () => {
        window.open(`mailto:${email}`, '_blank');
    };
    return (
        <Grid sx={type=== 'useCase'? useStyles.useCaseCardStyle : useStyles.cardStyle}>
            <LinkWrapper href={link}>
                {type === 'useCase' &&
                    <Box sx={useStyles.contentStyle}>
                        {icon && <Box sx={useStyles.iconStyle}>
                            <img src={icon} width={32} height={32} />
                        </Box>}
                        <Box sx={useStyles.cardMediaStyles}>
                            <Typography type="DESKTOP_TITLE_HEADING">{title}</Typography>
                            <Typography type="DESKTOP_SUBTITLE">{description}</Typography>
                        </Box>
                    </Box>}
                {(type === 'byProduct' || type === 'mostVisited') &&
                    <Box sx={useStyles.DemoscardMediaStyles}>
                        {image && <img src={image} />}
                        <Typography type="DESKTOP_BODY_HEADING">{title}</Typography>
                    </Box>}
                {type === 'footer' &&
                    <Box sx={useStyles.DemoscardMediaStyles} onClick={email? handleEmailButtonClick: undefined}>
                        {image && <img src={image} />}
                        {email ? <Typography type="DESKTOP_BODY_HEADING">{title}</Typography> : <Typography type="DESKTOP_BODY_HEADING">{title}</Typography>}
                    </Box>}
            </LinkWrapper>
        </Grid>
    );
};

export default HomePageCard;
