import * as React from 'react';
import { Grid } from '@mui/material';
import { BREAKPOINTS } from '../../../utils/theme';
import HomePageCard, { HomePageCardProps } from '../HomePageCard';

const useStyles = {
  useCaseCardGroupStyle: {
    position: 'relative',
    display: 'grid',
    gridGap: '20px',
    gridTemplateColumns: 'repeat(auto-fit,minmax(min(100%, 350px),2fr))',
    [BREAKPOINTS.MEDIA_MIN_1024]: {
      gridTemplateColumns: 'repeat(2,minmax(min(100%, 350px),2fr))',
    },
    [BREAKPOINTS.WIDTH_1024]: {
      gridTemplateColumns: 'repeat(2,minmax(min(100%, 250px),2fr))',
    },
    [BREAKPOINTS.MOBILE]: {
      gridGap: '16px',
    },
    marginTop: '20px',
    flex: '1',
    flexDirection: 'column',
  },
  mostVisitedCardGroupStyle: {
    position: 'relative',
    display: 'grid',
    gridGap: '20px',
    gridTemplateColumns: 'repeat(auto-fit,minmax(228.5px,1fr))',
    [BREAKPOINTS.DESKTOP]: {
      gridTemplateColumns: 'repeat(2,minmax(228.5px,1fr))',
    },
    [BREAKPOINTS.MEDIA_MIN_MAX]: {
      gridTemplateColumns: 'repeat(2,minmax(228.5px,1fr))',
    },
    [BREAKPOINTS.MOBILE]: {
      gridGap: '16px',
    },
    marginTop: '20px',
    flex: '1',
    flexDirection: 'column',
  }
};
export interface HomePageCardGroupProps {
  items: HomePageCardProps[];
  type?: string;
}
const HomePageCardGroup: React.FC<HomePageCardGroupProps> = ({
  items,
  type,
}) => {
  return (
    <Grid container sx={
      type === 'useCase' ? useStyles.useCaseCardGroupStyle : useStyles.mostVisitedCardGroupStyle
    }
      data-testid="home-page-card-group">
      {items.map((card) =>
        <Grid item key={card.title}>
          <HomePageCard {...card} type={type} />
        </Grid>
      )}
    </Grid>
  );
};

export default HomePageCardGroup;
