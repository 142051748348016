import VAULT from '../../static/images/vault.svg';
import NAV_CONNECTIONS from '../../static/images/nav-connections.svg';
import NAV_PIPELINES from '../../static/images/nav-pipelines.svg';
import FUNCTIONS from '../../static/images/functions.svg';
import ROUTES from '../docs-config/src';

export const ByProductCardItems = [
  {
    title: 'Vault',
    image: VAULT,
    link: ROUTES.createCustomVault,
  },
  {
    title: 'Connections',
    image: NAV_CONNECTIONS,
    link: ROUTES.connectionsOverview,
  },
  {
    title: 'Pipelines',
    image: NAV_PIPELINES,
    link: ROUTES.pipelinesOverview,
  },
  {
    title: 'Functions',
    image: FUNCTIONS,
    link: ROUTES.functionsOverview,
  },
];
