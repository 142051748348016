import * as React from 'react';
import { Grid } from '@mui/material';
import LinkWrapper from '../Link';
import { Box } from '@mui/system';
import Typography from '../Typography';

const useStyles = {
    cardMediaStyles: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'flex-start',
        gap: '10px',
        padding: '26px 30px 22px 0px',
    },
    DemoscardMediaStyles: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '20px',
        padding: '30px 30px 30px 30px',
    },
    cardStyle: {
        height: '100%',
    },
    textUnderline: {
        textDecoration: 'underline',
        cursor: 'pointer',
        paddingTop: '5px',
        paddingBottom: '5px'
    }
};

export interface NavigationListProps {
    heading1: string;
    heading2: string;
    url2: string;
    heading3: string;
    url3: string;
    heading4: string;
    url4: string;
}
const NavigationList: React.FC<NavigationListProps> = ({
    heading1, heading2, url2, heading3, url3, heading4, url4
}) => {
    return (
        <Grid sx={useStyles.cardStyle}>
            <Box sx={useStyles.cardMediaStyles}>
                <Typography type="DESKTOP_HEADING">{heading1}</Typography>
                <LinkWrapper href={url2}>
                    <Typography type="DESKTOP_BODY_SUBTITLE" sx={useStyles.textUnderline}>{heading2}</Typography>
                </LinkWrapper>
                <LinkWrapper href={url3}>
                    <Typography type="DESKTOP_BODY_SUBTITLE" sx={useStyles.textUnderline}>{heading3}</Typography>
                </LinkWrapper>
                <LinkWrapper href={url4}>
                    <Typography type="DESKTOP_BODY_HEADING">{heading4}</Typography>
                </LinkWrapper>
            </Box>
        </Grid>
    );
};

export default NavigationList;
