import React from 'react';
import Carousel from 'react-material-ui-carousel'
import data from '../../../utils/homepageQAData.json';
import { Box, Button, Grid } from '@mui/material';
import Typography from '../Typography';
import dot from '../../../../static/images/Ellipse.svg'
import homePageMessages from '../../../utils/messages/en/homePageMessages';
import LinkWrapper from '../Link';
import { BREAKPOINTS } from '../../../utils/theme';

const CarouselComponent = () => {
  const useStyles = {
    carouselStyle: {
      backgroundColor: 'rgba(0, 0, 0, 0.03)',
      padding: '20px',
      margin: 'auto',
      borderRadius: '4px',
    },
    innerBox: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      padding: '10px 0px 5px 10px'
    },
    headerTypography: {
      paddingBottom: '30px'
    },
    answerTypography: {
      padding: '10px 20px 20px 20px',
      [BREAKPOINTS.MOBILE]: {
        padding: '0px 30px 20px 30px',
      },
      [BREAKPOINTS.MEDIA_MIN_1400]: {
        height: '110px',
      },
      [BREAKPOINTS.MEDIA_MIN_768]: {
        height: '75px',
        padding: '0px 20px 20px 20px',
      },
      [BREAKPOINTS.DESKTOP]: {
        height: '85px',
      },
      [BREAKPOINTS.MEDIA_MIN_532]: {
        height: '95px',
        padding: '0px 20px 20px 20px',
      },
      [BREAKPOINTS.MEDIA_MIN_420]: {
        height: '120px',
        padding: '0px 5px 20px 5px',
      },
      [BREAKPOINTS.ANDROID]: {
        height: '200px',
        padding: '0px 5px 20px 5px',
      },
      [BREAKPOINTS.MEDIA_MIN_375]: {
        height: '150px',
        padding: '0px 3px 20px 3px',
      }
    },
    questionTypography: {
      height: '55px',
    },
    styledButton: {
      backgroundColor: 'transparent',
      paddingRight: '30px'
    }
  };

  return (
    <Box sx={useStyles.carouselStyle} data-testid="carousel">
      <Carousel
        navButtonsAlwaysInvisible
        IndicatorIcon={<img src={dot} alt='next'/>}
        indicatorIconButtonProps={{
          style: {
            marginRight: '10px',
          }
        }} activeIndicatorIconButtonProps={{
          style: {
            backgroundColor: 'rgba(62, 62, 78, 1)',
          }
        }}>
        {data.map((item, index) => (
          <Grid container sx={useStyles.innerBox} key={index}>
            <Grid item sx={useStyles.headerTypography}>
              <Typography type='DESKTOP_TITLE'>{homePageMessages.carouselHeading}</Typography>
            </Grid>
            <Grid item sx={useStyles.questionTypography}>
              <Typography type='DESKTOP_HEADING'>{item.question}</Typography>
            </Grid>
            <Grid item sx={useStyles.answerTypography}>
              <Typography variant="body1">
                {item.answer}
              </Typography>
            </Grid>
            <Grid item>
              <Button disableRipple variant='text' style={useStyles.styledButton}>
              <LinkWrapper href={item.route}>
                <Typography type='DESKTOP_BUTTON'>{homePageMessages.fullAnswer}</Typography>
              </LinkWrapper>
              </Button>
            </Grid>
          </Grid>
        ))}
      </Carousel>
    </Box>
  );
};

export default CarouselComponent;
