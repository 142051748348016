import ROUTES from "../docs-config/src";

export const NavigationListItems = [
    {
        heading1: 'Interactive demos',
        heading2: 'Tokenization',
        url2: 'https://tokenization-demo.skyflow.app/',
        heading3: 'Governance',
        url3: 'https://governance-demo.skyflow.app/',
        heading4: 'See all interactive demos',
        url4: ROUTES.demos,
  },
  {
        heading1: 'Samples',
        heading2: 'JS',
        url2: 'https://github.com/skyflowapi/skyflow-js/tree/master/samples',
        heading3: 'React',
        url3: 'https://github.com/skyflowapi/skyflow-react-js/tree/master/samples/SkyflowElements',
        heading4: 'See all samples',
        url4: 'https://github.com/skyflowapi',
  },
  {
      heading1: 'Resources',
      heading2: 'Common Errors',
      url2: ROUTES.commonErrors,
      heading3: 'Release Notes',
      url3: ROUTES.releaseNotes,
      heading4: 'See all resources',
      url4: ROUTES.apiReference.home,
}
  ];
  