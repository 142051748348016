import ROUTES from "../docs-config/src";

export const MostVisitedCardItems = [
  {
    title: 'Authenticate',
    link: ROUTES.apiAuthentication,
  },
  {
    title: 'What is Skyflow?',
    link: ROUTES.productOverview,
  },
  {
    title: 'Data API',
    link: ROUTES.apiReference.record,
  },
  {
    title: 'Management API',
    link: ROUTES.apiReference.management,
  },
];
