import * as React from 'react';
import { Grid } from '@mui/material';
import { BREAKPOINTS } from '../../../utils/theme';
import NavigationList, { NavigationListProps } from '../NavigationList';

const useStyles = {
  cardGroupStyle: {
    position: 'relative',
    display: 'flex',
    gridGap: '20px',
    [BREAKPOINTS.MOBILE]: {
      gridGap: '16px',
    },
    marginTop: '20px',
    flex: '1',
    flexDirection: 'row',
    justifyContent:'space-between'
  },
};
export interface NavigationListGroupProps {
  items: NavigationListProps[];
}
const NavigationListGroup: React.FC<NavigationListGroupProps> = ({
  items,
}) => {
  return (
    <Grid container sx={useStyles.cardGroupStyle}
    data-testid="navigation-list">
      {items.map((item) =>
        <Grid item
        >
          <NavigationList {...item} />
        </Grid>
      )}
    </Grid>
  );
};

export default NavigationListGroup;
